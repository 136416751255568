import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import BroadcastPage from './modules/broadcast/BroadcastPage';
import Layout from './modules/common/Layout';
import { NotFoundPage } from './modules/common/error/NotFoundPage';
import { ServerErrorPage } from './modules/common/error/ServerErrorPage';
import InvoicePage from './modules/generate-invoice/InvoicePage';
import InvoiceLogDetailPage from './modules/invoice-log-detail/InvoiceLogDetailPage';
import InvoiceLogsPage from './modules/invoice-logs-overview/InvoiceLogsPage';
import LoginPage from './modules/login/LoginPage';

Sentry.init({
  environment: import.meta.env.MODE,
  dsn: 'https://7c520f2dca0a43658bc04b9d2548909c@o4505211128119296.ingest.sentry.io/4505261872775168',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/admin\.rstransport\.com\.sg\/api/,
      ],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.25, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (import.meta.env.PROD) {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = function () {};
}

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          colors: {
            'deep-purple': [
              '#EDE7F6',
              '#D1C4E9',
              '#B39DDB',
              '#9575CD',
              '#7E57C2',
              '#673AB7',
              '#5E35B1',
              '#512DA8',
              '#4527A0',
              '#311B92',
            ],
          },
          primaryColor: 'deep-purple',
          colorScheme: 'light',
        }}
      >
        <ModalsProvider>
          <Notifications position="top-right" />
          <BrowserRouter>
            <Routes>
              <Route
                path="/login"
                element={<LoginPage />}
                errorElement={<ServerErrorPage />}
              />
              <Route path="/portal" element={<Layout />}>
                <Route
                  index={true}
                  element={<Navigate to={'/portal/invoice/generate'} />}
                />
                <Route errorElement={<ServerErrorPage />} />

                <Route path="invoice">
                  <Route
                    index={true}
                    element={<Navigate to={'/portal/invoice/generate'} />}
                  />
                  <Route path="generate" element={<InvoicePage />} />

                  <Route path="log">
                    <Route index={true} element={<InvoiceLogsPage />} />
                    <Route path=":logId" element={<InvoiceLogDetailPage />} />
                  </Route>
                </Route>

                <Route path="broadcast" element={<BroadcastPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Route>
              <Route path="/" element={<Navigate to={'/login'} />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </BrowserRouter>
        </ModalsProvider>
      </MantineProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
