import {
  AppShell,
  Button,
  Container,
  Group,
  Header,
  Image,
  Skeleton,
  createStyles,
  getStylesRef,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconLogout, IconX } from '@tabler/icons-react';
import React, { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { auth, logout } from '../../utils/auth';

const useStyles = createStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({
        variant: 'light',
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor })
        .color,
    },
  },

  linkIcon: {
    ref: getStylesRef('icon'),
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
    marginRight: theme.spacing.sm,
  },
}));

const links = [
  { link: '/portal/invoice', label: 'Generate Invoice' },
  { link: '/portal/broadcast', label: 'Broadcast Email' },
  { link: '/portal/invoice/log', label: 'Invoice Logs' },
];

export default function Layout() {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate('/login');
  }, [user, loading]);

  const handleLogout = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    try {
      logout();
      navigate('/login');
    } catch {
      notifications.show({
        message: 'Error Ocurred. Please try again.',
        color: 'red',
        icon: <IconX />,
      });
    }
  };

  const items = links.map((link) => (
    <NavLink key={link.label} to={link.link} className={classes.link}>
      {link.label}
    </NavLink>
  ));

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      header={
        <Header height={60} mb={120}>
          <Container className={classes.header}>
            <Image my="xl" maw={55} src="/logo.png" alt="RS Transport Logo" />
            <Group spacing={5}>{items}</Group>
            <Button
              variant="subtle"
              color="gray"
              leftIcon={
                <IconLogout className={classes.linkIcon} stroke={1.5} />
              }
              onClick={(e) => handleLogout(e)}
            >
              Logout
            </Button>
          </Container>
        </Header>
      }
    >
      <Skeleton visible={loading}>
        <Outlet />
      </Skeleton>
    </AppShell>
  );
}
