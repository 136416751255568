import { auth } from './auth';
import axios from 'axios';
import { BaseError } from '../../server/errors/baseError';

let SERVER_PREFIX: string;

switch (import.meta.env.MODE) {
  case 'staging':
    SERVER_PREFIX = 'https://admindev.rstransport.com.sg/api';
    break;
  case 'production':
    SERVER_PREFIX = 'https://admin.rstransport.com.sg/api';
    break;
  default:
    SERVER_PREFIX = 'http://localhost:3000/api';
}

interface loginRequest {
  email: string;
  password: string;
}

axios.defaults.baseURL = SERVER_PREFIX;
axios.interceptors.request.use(
  async (config) => {
    const token = await auth.currentUser?.getIdToken();
    if (!token) throw new BaseError(401, true, 'Unauthorised');
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      throw new BaseError(
        error.response.status,
        true,
        error.response.data.message
      );
    }
    throw error;
  }
);

const apiHelper = {
  async generateInvoice(data: FormData, responseType: 'blob' | 'json') {
    const response = await axios.post('/invoice', data, {
      responseType,
    });
    return response.data;
  },
  async broadcastEmail(data: FormData) {
    const token = await auth.currentUser?.getIdToken();
    return fetch(`${SERVER_PREFIX}/broadcast`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: data,
    });
  },
  async getInvoiceGroups() {
    const response = await axios.get('/invoice-group');
    return response.data;
  },
  async getInvoicesByInvoiceGroup(invoiceGroupId: string) {
    const response = await axios.get(
      `/invoice-group/invoices/${invoiceGroupId}`
    );
    return response.data;
  },
  async markSingleInvoiceAsPaid(invoiceId: string) {
    const response = await axios.post(`/invoice/paid/admin/${invoiceId}`);
    return response.data;
  },
  async markInvoicesAsPaid(invoiceIds: string[]) {
    const response = await axios.post(`/invoice/paid/admin`, invoiceIds);
    return response.data;
  },
  async downloadInvoices(invoiceGroupId: number) {
    const response = await axios.get(
      `/invoice-group/download/${invoiceGroupId}`,
      {
        responseType: 'blob',
      }
    );
    return response.data;
  },
  async sendSingleReminderEmail(invoiceId: string) {
    const response = await axios.post(`/invoice/reminder/${invoiceId}`);
    return response.data;
  },
  login(data: loginRequest) {
    return fetch(`${SERVER_PREFIX}/user/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
  },
  logout() {
    return fetch(`${SERVER_PREFIX}/user/logout`, {
      method: 'POST',
    });
  },
};

export default apiHelper;
