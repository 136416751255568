import {
  Button,
  FileInput,
  Flex,
  LoadingOverlay,
  Radio,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
} from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconEye, IconMail, IconX } from '@tabler/icons-react';
import React, { useState } from 'react';
import apiHelper from '../../utils/api';
import emailTemplateString from './EmailTemplate.html?raw';

const BroadcastForm = () => {
  const [loading, setLoading] = useState(false);
  const [emailFile, setEmailFile] = useState<File | null>(null);
  const [attachmentFile, setAttachmentFile] = useState<File | null>(null);

  const form = useForm({
    initialValues: {
      school: '',
      subject: 'Renewal of Bus Service for Y2024 for {{ childName }}',
      header: 'Renewal',
      content:
        'We are pleased to inform you that the school has appointed RS Transport Services for 2024 provide Daily School Bus Services and Bus Services for after school activities. \n\nParents of P2 - P6 students in 2024 of  who are interested in engaging the school bus services in 2024, please complete the attached request form link and submit to RS Transport as soon as possible and no later than 31 October 2023. ',
    },
    validate: {
      school: isNotEmpty('Please select a school'),
      subject: isNotEmpty('Please enter a subject'),
      header: isNotEmpty('Please enter a header'),
    },
    validateInputOnBlur: true,
  });

  const generateAndDownloadEmail = () => {
    // Replace placeholders in the HTML template with dynamic data

    const populatedEmail = emailTemplateString
      .replace('{{ header }}', form.values.header)
      .replace('{{ content }}', form.values.content.replace(/\n/g, '<br>'))
      .replace('{{ recipients }}', 'Father Name and Mother Name');

    // Create a Blob and trigger the download
    const newWindow = window.open();
    if (newWindow != null) {
      newWindow.document.open();
      newWindow.document.write(populatedEmail);
      newWindow.document.close();
    } else {
      notifications.show({
        message: 'Please disable your browser popup blocker',
        color: 'red',
        icon: <IconX />,
      });
    }
  };

  const handleSubmit = async () => {
    if (emailFile == null) {
      notifications.show({
        message: 'Please upload the CSV file with the recipient emails',
        color: 'red',
        icon: <IconX />,
      });
    } else {
      setLoading(true);
      // prepare data for request
      const formData = new FormData();
      formData.append('emailFile', emailFile, 'RegBus.csv');
      if (attachmentFile != null)
        formData.append('attachmentFile', attachmentFile, attachmentFile.name);
      formData.append('form', JSON.stringify(form.values));

      try {
        const response = await apiHelper.broadcastEmail(formData);
        console.log(response);

        if (response.ok) {
          form.reset();

          setLoading(false);
          notifications.show({
            title: 'Broadcast Emails Sent!',
            message: 'You should receive a status email shortly.',
            icon: <IconCheck />,
            autoClose: 5000,
            withCloseButton: true,
          });
        } else {
          setLoading(false);
          const res = await response.json();
          notifications.show({
            message: res.message,
            color: 'red',
            icon: <IconX />,
            autoClose: false,
          });
        }
        setEmailFile(null);
        setAttachmentFile(null);
      } catch (err) {
        setLoading(false);
        console.log(err);
        let message = 'An error has occurred. Please try again later.';
        if (err instanceof Error) {
          message = err.message;
        }
        notifications.show({
          title: 'An error has occurred!',
          message: message,
          color: 'red',
          icon: <IconX />,
        });
      }
    }
  };

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Title>Mass Broadcast Emails</Title>
      <Text>
        Please enter the subject line, email content and upload any attachment
        (optional) as needed.
      </Text>
      <Stack
        pos={'relative'}
        spacing={'10px'}
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        })}
      >
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Radio.Group
            py="lg"
            name="schoolSelect"
            label="School"
            error="Please select school"
            required={true}
            {...form.getInputProps('school')}
          >
            <Radio value="SJI" label="SJI Junior" />
            <Radio value="SAJS" label="St Andrew's" />
            <Radio value="SMPS" label="St Margaret's " />
            <Radio value="KHS" label="Kong Hwa School" />
          </Radio.Group>

          <TextInput
            py="lg"
            placeholder="Enter Subject Line"
            description="{{ childName }} is a placeholder and will be replaced with actual child name when email is sent"
            label="Subject"
            required={true}
            {...form.getInputProps('subject')}
          />

          <TextInput
            py="lg"
            placeholder="Enter Email Header"
            label="Email Header"
            description="Text shown at the top of email"
            required={true}
            {...form.getInputProps('header')}
          />
          <Textarea
            placeholder="Email Content"
            label="Email Content"
            required={true}
            autosize
            minRows={4}
            {...form.getInputProps('content')}
          />

          <FileInput
            py="lg"
            placeholder="Select CSV File"
            label="CSV File With Recipient Email Addresses"
            value={emailFile}
            required={true}
            accept="text/csv"
            onChange={setEmailFile}
          ></FileInput>

          <FileInput
            py="lg"
            placeholder="Select File"
            label="Email Attachment"
            value={attachmentFile}
            onChange={setAttachmentFile}
          ></FileInput>

          <Flex
            gap="md"
            justify="center"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Button
              type="button"
              variant="outline"
              leftIcon={<IconEye size="1rem" />}
              onClick={generateAndDownloadEmail}
            >
              Preview Email
            </Button>
            <Button
              disabled={loading}
              type="submit"
              leftIcon={<IconMail size="1rem" />}
            >
              Send Emails
            </Button>
          </Flex>
        </form>
      </Stack>
    </>
  );
};

export default BroadcastForm;
