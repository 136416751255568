import { Alert, List, LoadingOverlay, Stack, Title } from '@mantine/core';
import { Invoice, InvoiceState } from '@prisma/client';
import { IconAlertCircle } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';
import apiHelper from '../../utils/api';
import InvoiceLogTable from './InvoiceLogDetailTable';
import { InvoiceStatsCard } from './StatsCard';

const InvoiceLogDetailPage = () => {
  const { logId } = useParams();

  const { isPending, isError, data, error } = useQuery({
    queryKey: ['invoices'],
    queryFn: () => apiHelper.getInvoicesByInvoiceGroup(logId ?? ''),
  });

  const statuses = data?.map((invoice: Invoice) => invoice.currentStatus);

  const stats = getInvoiceStatusCounts(data, statuses);
  const statCounts = {};
  stats.forEach((stat) => {
    statCounts[stat.label] = stat.value;
  });

  return (
    <>
      <Title order={1} mb={10}>
        Invoice Log Detail
      </Title>
      <List fz="sm">
        <List.Item>
          This page provides an overview of the status of invoices sent via
          email. Hover over the numbers to see an explanation of each status.
        </List.Item>
        <List.Item>
          Please allow up to 15 minutes for the invoice emails to be sent out.
          You may click the refresh button at the top right of the table to get
          the latest status.
        </List.Item>
        <List.Item>
          If you wish to send a WhatsApp message to the parents, you may click
          the WhatsApp icon in the actions column of the table. This will open
          the WhatsApp web interface with a pre-filled message to the parent.
        </List.Item>
      </List>
      <Stack pos={'relative'}>
        {isError && (
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="An error has ocurred retrieving data. Please refresh the page to try again."
            color="red"
          >
            {error.message}
          </Alert>
        )}

        <LoadingOverlay visible={isPending} overlayBlur={2} />
        <InvoiceStatsCard
          title="Invoice Progress"
          completed={
            statuses?.filter((invoice: InvoiceState) => invoice === 'PAID')
              .length
          }
          delivered={
            statuses?.filter((invoice: InvoiceState) => invoice === 'DELIVERED')
              .length
          }
          total={statuses?.length}
          stats={stats}
        />

        <InvoiceLogTable invoices={data ?? []} logId={parseInt(logId ?? '')} />
      </Stack>
    </>
  );
};

export default InvoiceLogDetailPage;

function getInvoiceStatusCounts(
  data: Array<Invoice>,
  statuses: Array<InvoiceState>
) {
  return [
    {
      value: statuses?.filter((invoice: InvoiceState) => invoice === 'ACCEPTED')
        .length,
      label: 'Accepted',
      tooltip:
        'Invoice has been accepted by email provider and will be sent soon',
    },
    {
      value: statuses?.filter(
        (invoice: InvoiceState) => invoice === 'DELIVERED'
      ).length,
      label: 'Delivered',
      tooltip: 'Invoice email has been delivered to the parent',
    },
    {
      value: statuses?.filter((invoice: InvoiceState) => invoice === 'REJECTED')
        .length,
      label: 'Rejected',
      tooltip: 'Email provider attempted to send email but email bounced',
    },
    {
      value: statuses?.filter((invoice: InvoiceState) => invoice === 'FAILED')
        .length,
      label: 'Failed',
      tooltip: 'Invoice email was not sent to email provider due to errors',
    },
    // {
    //   value: statuses?.filter((invoice: InvoiceState) => invoice === 'UNPAID')
    //     .length,
    //   label: 'Unpaid',
    //   tooltip: 'Invoice has not been paid by the parent',
    // },
  ];
}
