import {
  Button,
  Center,
  Checkbox,
  FileInput,
  List,
  LoadingOverlay,
  Radio,
  Select,
  Stack,
  Text,
  TextInput,
  Textarea,
  Title,
  Tooltip,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { isNotEmpty, useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import {
  IconCalendar,
  IconCheck,
  IconFileInvoice,
  IconX,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiHelper from '../../utils/api';

dayjs.extend(isSameOrAfter);

const InvoiceForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const today = dayjs();

  const form = useForm({
    initialValues: {
      school: '',
      invoiceType: '',
      invoiceNo: '',
      invoiceDate: today,
      dueDate: today.add(7, 'day'),
      subject: 'Invoice for {{ childname }} {{ mth }} {{ invoiceType }}',
      content:
        "Your invoice for {{ childname }}'s bus fee for {{ mth }} is attached. Please make payment of {{ fare }} by {{ duedate }}. The payment details can be found in the invoice.\n\nPlease indicate {{ childname }} as the reference when making payment.\n\nIf you have already paid this invoice, please click the button below or follow the link below to indicate payment.\n\n",
      email: false,
    },
    validate: {
      school: isNotEmpty('Please select a school'),
      invoiceType: isNotEmpty('Please select an invoice type'),
      invoiceNo: (value, values) =>
        values.invoiceType !== 'retainer' && !value
          ? 'Please select an invoice no'
          : null,
      content: isNotEmpty('Please enter the email content'),
      dueDate: (value, values) =>
        dayjs(value).isSameOrAfter(dayjs(values.invoiceDate))
          ? null
          : 'Invoice Due Date must be after Invoice Date',
    },
    validateInputOnBlur: true,
  });

  const invoiceNoOptions =
    (form.values.school === 'SAJS' || form.values.school === 'SMPS') &&
    form.values.invoiceType === 'CCA'
      ? [
          { value: 'T1', label: 'T1' },
          { value: 'T2', label: 'T2' },
          { value: 'T3', label: 'T3' },
          { value: 'T4', label: 'T4' },
        ]
      : [
          { value: 'Jan', label: 'Jan' },
          { value: 'Feb', label: 'Feb' },
          { value: 'Mar', label: 'Mar' },
          { value: 'Apr', label: 'Apr' },
          { value: 'May', label: 'May' },
          { value: 'Jun', label: 'Jun' },
          { value: 'Jul', label: 'Jul' },
          { value: 'Aug', label: 'Aug' },
          { value: 'Sep', label: 'Sep' },
          { value: 'Oct', label: 'Oct' },
          { value: 'Nov', label: 'Nov' },
          { value: 'Dec', label: 'Dec' },
        ];

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (file == null) throw Error('Please upload a CSV file');

      // prepare data for request
      const formData = new FormData();
      formData.append('file', file, 'RegBus.csv');
      formData.append('form', JSON.stringify(form.values));

      if (!form.values.email) {
        const data = await apiHelper.generateInvoice(formData, 'blob');
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.download = 'Invoices.zip';
        a.click();
        window.URL.revokeObjectURL(url);

        notifications.show({
          title: 'Invoices Successfully Generated!',
          message:
            'Please download and verify that invoices have been generated correctly.',
          icon: <IconCheck />,
          autoClose: 5000,
          withCloseButton: true,
        });
      } else {
        const data = await apiHelper.generateInvoice(formData, 'json');
        navigate(`/portal/invoice/log/${data.invoiceGroupId}`);
        notifications.show({
          title: 'Invoices Successfully Generated!',
          message:
            'You have been redirected to the invoice logs page. You will also receive an email within 15 minutes regarding the statuses of the invoices sent.',
          icon: <IconCheck />,
          autoClose: 10000,
          withCloseButton: true,
        });
      }
      form.reset();
    } catch (err) {
      console.error(err);
      notifications.show({
        title: 'An error has ocurred!',
        message: err.message ?? 'Please try again later',
        color: 'red',
        icon: <IconX />,
        autoClose: false,
      });
    } finally {
      setLoading(false);
      setFile(null);
    }
  };

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Stack
        pos="relative"
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        })}
      >
        <Title>Generate an Invoice</Title>
        <Text fz="sm">
          To successfully generate invoices, please ensure the following:
        </Text>
        <List fz="sm">
          <List.Item>
            Every child has a valid 4 digit ID in the ID column. If you do not
            want to generate an invoice for the child, please ensure they do not
            have a 4 digit ID in the ID column.
          </List.Item>
          <List.Item>
            There must be a FARE column that is formatted as Number.
          </List.Item>
        </List>
        <Text fz="sm">
          After clicking the generate invoice button, the application will
          generate PDF invoices and prompt you to download them. If you have
          checked the box, it will also send emails.
        </Text>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <Radio.Group
            py="lg"
            name="schoolSelect"
            label="School"
            error="Please select school"
            required={true}
            {...form.getInputProps('school')}
          >
            <Radio value="SJI" label="SJI Junior" />
            <Radio value="SAJS" label="St Andrew's" />
            <Radio value="SMPS" label="St Margaret's " />
            <Radio value="KHS" label="Kong Hwa School" />
          </Radio.Group>
          <Radio.Group
            py="lg"
            name="invoiceTypeSelect"
            label="Invoice Type"
            error="Please select invoice type"
            required={true}
            {...form.getInputProps('invoiceType')}
          >
            <Radio value="REGULAR" label="Regular Bus" />
            <Radio value="CCA" label="CCA Bus" />
            <Radio value="RETAINER" label="Retainer Fee" />
          </Radio.Group>

          <Select
            label="Invoice No."
            placeholder="Select one"
            description="The selected value will appear in the Invoice No. and the Mth column in the table of the invoice. If the invoice type is retainer, this field is not required."
            required={true}
            disabled={form.values.invoiceType === 'RETAINER'}
            data={invoiceNoOptions}
            {...form.getInputProps('invoiceNo')}
          />

          <DatePickerInput
            icon={<IconCalendar size="1.1rem" stroke={1.5} />}
            label="Invoice Date"
            placeholder="Pick date"
            description="Default value is today"
            error="Please select invoice date"
            required={true}
            valueFormat="DD/MM/YYYY"
            mx="auto"
            my="lg"
            maw={400}
            {...form.getInputProps('invoiceDate')}
          />
          <DatePickerInput
            icon={<IconCalendar size="1.1rem" stroke={1.5} />}
            label="Due Date"
            description="Default value is 7 days from today"
            placeholder="Pick date"
            error="Please select due date"
            required={true}
            valueFormat="DD/MM/YYYY"
            mx="auto"
            my="lg"
            maw={400}
            {...form.getInputProps('dueDate')}
          />
          <Tooltip
            multiline
            withArrow
            w={250}
            label="For {{ invoiceType }}: Regular will show School Bus Fee, CCA will show CCA Bus Fee, Retainer will show Fee"
          >
            <TextInput
              py="lg"
              placeholder="Enter Subject Line"
              description="The words surrounded by {{ }} are placeholders and will be replaced with the actual values when email is sent"
              label="Subject"
              required={true}
              {...form.getInputProps('subject')}
            />
          </Tooltip>
          <Textarea
            py="lg"
            placeholder="Email Content"
            label="Email Content"
            description="The words surrounded by {{ }} are placeholders and will be replaced with the actual values when email is sent"
            autosize
            required={true}
            minRows={4}
            {...form.getInputProps('content')}
          />
          <FileInput
            placeholder="Select CSV File"
            label="CSV File"
            value={file}
            required={true}
            accept="text/csv"
            onChange={setFile}
          ></FileInput>
          <Checkbox
            py="lg"
            label="Check Box to Send Emails"
            {...form.getInputProps('email')}
          />
          <Center>
            <Button
              disabled={loading}
              type="submit"
              leftIcon={<IconFileInvoice size="1rem" />}
            >
              Generate Invoice
            </Button>
          </Center>
        </form>
      </Stack>
    </>
  );
};

export default InvoiceForm;
