export class BaseError extends Error {
  statusCode: number;
  isOperational: boolean;
  constructor(statusCode: number, isOperational: boolean, description: string) {
    super(description);

    Object.setPrototypeOf(this, new.target.prototype);
    this.name = this.constructor.name;
    this.statusCode = statusCode;
    this.isOperational = isOperational;
    Error.captureStackTrace(this);
  }
}
