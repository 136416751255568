import {
  ActionIcon,
  Button,
  Flex,
  Grid,
  Table,
  Text,
  Tooltip,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { Status } from '@prisma/client';
import {
  IconBrandWhatsapp,
  IconDownload,
  IconRefresh,
  IconX,
} from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleGlobalFilterButton,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { InvoiceLogDetail } from '../../../types/lib';
import apiHelper from '../../utils/api';

function openWhatsapp(phone: string, name: string, fare: number) {
  return window.open(
    `https://wa.me/65${phone}?text=Hi ${name}, your school bus invoice is attached. Please make payment of $${fare} within 7 days. Thank you!`
  );
}

function sendWhatsappReminder(row: InvoiceLogDetail) {
  if (row.parents.length === 1) {
    openWhatsapp(row.parents[0].phone, row.parents[0].name, row.fare);
  } else {
    modals.open({
      title: 'Select Parent',
      children: (
        <>
          <Table withBorder withColumnBorders highlightOnHover>
            <thead>
              <tr>
                <th>Type</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {row.parents.map((parent, index) => {
                return (
                  <tr key={index}>
                    <td>{parent.type}</td>
                    <td>{parent.name}</td>
                    <td>{parent.phone}</td>
                    <td
                      onClick={() => {
                        modals.closeAll();
                        openWhatsapp(parent.phone, parent.name, row.fare);
                      }}
                    >
                      <ActionIcon>
                        <IconBrandWhatsapp />
                      </ActionIcon>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      ),
    });
  }
}

// async function sendReminder(row: InvoiceLogDetail) {
//   try {
//     console.log(row);
//     await apiHelper.sendSingleReminderEmail(row.id);
//   } catch (err) {
//     console.error(err);
//     notifications.show({
//       title: 'An error has ocurred!',
//       message: err.message,
//       color: 'red',
//       icon: <IconX />,
//     });
//   }
// }

function displayDetail(row: MRT_Row<InvoiceLogDetail>) {
  return (
    <Grid>
      <Grid.Col span={5}>
        <Text fw={700} fz={'lg'}>
          Parent Info
        </Text>
        <Table
          withBorder
          withColumnBorders
          highlightOnHover
          sx={{ cursor: 'pointer' }}
        >
          <thead>
            <tr>
              <th>Type</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile</th>
            </tr>
          </thead>
          <tbody>
            {row.original.parents.map((parent, index) => {
              return (
                <tr key={index}>
                  <td>{parent.type}</td>
                  <td>{parent.name}</td>
                  <td>{parent.email}</td>
                  <td onClick={() => sendWhatsappReminder(row.original)}>
                    {parent.phone}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Grid.Col>
      <Grid.Col span={7}>
        <Text fw={700} fz={'lg'}>
          Events
        </Text>
        <Table striped withBorder withColumnBorders captionSide="top">
          <thead>
            <tr>
              <th>Date Time</th>
              <th>Event</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {row.original.statuses.map((event: Status, index: number) => {
              return (
                <tr key={index}>
                  <td>
                    {dayjs(event.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                  </td>
                  <td>{event.status}</td>
                  <td>{event.details}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Grid.Col>
    </Grid>
  );
}

const InvoiceLogTable = ({ invoices, logId }) => {
  const [data, setData] = useState(invoices);
  const queryClient = useQueryClient();
  useEffect(() => {
    setData(...[Object.values(invoices)]);
  }, [invoices]);

  // const markSingleInvoicePaid = useMutation({
  //   mutationFn: async (invoiceId: string) => {
  //     notifications.show({
  //       id: 'loading',
  //       loading: true,
  //       message: 'Marking Invoice as Paid',
  //     });
  //     return await apiHelper.markSingleInvoiceAsPaid(invoiceId);
  //   },
  //   onSuccess: () => {
  //     queryClient.invalidateQueries({ queryKey: ['invoices'] });
  //     notifications.show({
  //       message: 'The invoice has been marked as paid.',
  //       icon: <IconCheck />,
  //       autoClose: 5000,
  //       withCloseButton: true,
  //     });
  //   },
  //   onError: (err) => {
  //     notifications.show({
  //       title: 'An error has ocurred!',
  //       message: err.message,
  //       color: 'red',
  //       icon: <IconX />,
  //     });
  //   },
  //   onSettled: () => {
  //     notifications.hide('loading');
  //   },
  // });

  const downloadInvoices = async () => {
    notifications.show({
      id: 'loading',
      loading: true,
      message: 'Downloading Invoices',
      autoClose: false,
    });

    try {
      const data = await apiHelper.downloadInvoices(logId ?? -1);
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = 'Invoices.zip';
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      notifications.show({
        title: 'An error has ocurred!',
        message: err.message,
        color: 'red',
        icon: <IconX />,
      });
    } finally {
      notifications.hide('loading');
    }
  };

  const columns = useMemo<MRT_ColumnDef<InvoiceLogDetail>[]>(
    () => [
      {
        accessorKey: 'child.rsId',
        header: 'Child ID',
      },
      {
        accessorKey: 'currentStatus',
        header: 'Status',
        filterVariant: 'multi-select',
      },
      {
        accessorKey: 'child.name',
        header: 'Child Name',
        filterVariant: 'text',
      },
      {
        accessorKey: 'fare',
        header: 'Fare',
        filterVariant: 'range-slider',
        Cell: ({ cell }) => (
          <span>${cell.getValue<number>().toLocaleString()}</span>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableFacetedValues: true,
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Flex>
        <Tooltip label="Send Reminder Whatsapp">
          <ActionIcon
            // disabled={row.original.currentStatus === 'PAID'}
            onClick={() => sendWhatsappReminder(row.original)}
          >
            <IconBrandWhatsapp />
          </ActionIcon>
        </Tooltip>
        {/* <Tooltip label="Mark as Paid">
          <ActionIcon
            disabled={row.original.currentStatus === 'PAID'}
            onClick={() => markSingleInvoicePaid.mutate(row.original.id)}
          >
            <IconReportMoney />
          </ActionIcon>
        </Tooltip> */}
      </Flex>
    ),
    initialState: {
      density: 'xs',
      showColumnFilters: true,
    },
    enableClickToCopy: true,
    renderDetailPanel: ({ row }) => displayDetail(row),
    enableRowSelection: true,
    getRowId: (originalRow) => originalRow.id,
    renderTopToolbarCustomActions: () => (
      <Flex gap={'md'} align={'center'}>
        <Button leftIcon={<IconDownload />} onClick={() => downloadInvoices()}>
          Download Invoices
        </Button>
        {/* <Button
          leftIcon={<IconReportMoney />}
          disabled={!table.getIsSomeRowsSelected()}
          onClick={() => {
            markInvoicesAsPaid.mutate(
              Object.keys(table.getState().rowSelection)
            );
          }}
        >
          Mark as Paid
        </Button> */}
      </Flex>
    ),
    renderToolbarInternalActions: ({ table }) => (
      <Flex gap="xs" align="center">
        <Tooltip label="Refresh Data">
          <ActionIcon
            onClick={() =>
              queryClient.invalidateQueries({ queryKey: ['invoices'] })
            }
          >
            <IconRefresh />
          </ActionIcon>
        </Tooltip>
        <MRT_ToggleGlobalFilterButton table={table} />
        <MRT_ToggleFiltersButton table={table} />
        <MRT_ShowHideColumnsButton table={table} />
      </Flex>
    ),
  });

  return <MantineReactTable table={table} />;
};

export default InvoiceLogTable;
